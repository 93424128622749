<main>
  <img class="logo" src="/toplogo.png" alt="Tezan" />
  <video
    src="/dbanim.mp4"
    autoplay="autoplay"
    loop="loop"
    muted
    defaultMuted
    playsinline
    oncontextmenu="return false;"
    preload="auto"
  >
    <track kind="captions" />
  </video>
  <img class="botlogo logo" src="/botlogo.png" alt="Records" />
</main>

<style>
  * {
    transform: scale(1);
    transition: all 1s ease-in-out;
  }
  main {
    width: 100%;
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 300px;
  }
  video {
    width: 400px;
  }
  .botlogo {
    width: 400px;
  }
  .logo {
    background-color: black;
    cursor: pointer;
  }
  .logo:hover {
    background: repeating-linear-gradient(
      -45deg,
      var(--color-gd-1) 0%,
      var(--color-gd-2) 16.6%,
      var(--color-gd-3) 33.2%,
      var(--color-gd-1) 50%
    );
    background-clip: padding;
    background-size: 64rem 64rem;
    animation: logoslide 1s linear infinite forwards;
  }

  @media (max-width: 600px) {
    * {
      transform: scale(0.8);
      transition: all 1s ease-in-out;
    }
  }
</style>
