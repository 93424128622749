<main>
  <h1 class="link-font">this page doesn't exist</h1>
</main>

<style>
  main {
    width: 100%;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h1 {
    padding: 0;
    margin: 0;
  }
</style>
