<script>
  import Index from './index/Index.svelte'
  import Footer from './footer/Footer.svelte'
  import Error from './Error/Error.svelte'
  import url from '../scripts/url'
</script>

{#if $url.hash === '' || $url.hash === '#/'}
  <Index />
{:else}
  <Error />
{/if}

<Footer {url} />

<style>
</style>
